import React from "react";

import styles from "../styles/why_cbdcx.module.css";

export default function OurMission() {
  return (
    <section className={styles["our__mission_section"]}>
      <div className={styles["container"]}>
        <div className={styles["cbdcx__container"]}>
          <h1>Our Mission</h1>
          <p>
          At CCX, our mission is to bridge the gap by enabling seamless transactions between digital currencies across the globe. We believe in empowering individuals and businesses with greater financial flexibility and accessibility.
          </p>
        </div>
      </div>
    </section>
  );
}
