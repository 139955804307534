import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

import styles from "../styles/landing.module.css";
import Globe, { GlobeMethods } from "react-globe.gl";

export default function LandingComponent() {
  const statistics = [
    {
      title: "Countries which will be impacted by us",
      value: "160",
      prefix: "",
      suffix: "+",
    },
    {
      title: "Potential transactions annually",
      value: "100",
      prefix: "$",
      suffix: "B+",
    },
    {
      title: "People in developing countries",
      value: "6",
      prefix: "",
      suffix: "+ Billion",
    },
  ];
  const [popData, setPopData] = useState([]);

  useEffect(() => {
    // fetch("https://dall-e.b-cdn.net/log.json")
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setPopData(data);
    //   });
    // globeEl.current!.controls().autoRotate = true;
    // globeEl.current!.controls().autoRotateSpeed = 0.1;
  }, []);

  return (
    <section className={styles["landing__section"]}>
      <div className={styles["container"]}>
        <div className={styles["landing__container"]}>
          <div className={styles["landing__content"]}>
            <h1>
              Powering the Future of <br /> Cross Border Payments
            </h1>
            <p>
            First AI-powered cross-border payments platform that seamlessly facilitates swaps between digital currencies and stablecoins in emerging economies.

            </p>
          </div>
          <div className={styles["landing__stats"]}>
            {statistics.map((stats, index) => {
              return (
                <div className={styles["stats__container"]} key={index}>
                  <CountUp
                    start={0}
                    end={Number(stats.value)}
                    prefix={stats.prefix}
                    suffix={stats.suffix}
                    className={styles["counter"]}
                  />
                  <p>{stats.title}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* <div className={styles["globe__container"]}>
        <Globe ref={globeEl} backgroundColor="white" hexBinPointsData={popData} enablePointerInteraction={false} />
      </div> */}
    </section>
  );
}
